import React from 'react'
import {graphql, PageProps} from 'gatsby'
import Helmet from 'react-helmet'
import StandardLayout from '../layouts/standard'
import Gallery from '../components/gallery'
import {pluck} from '../util'
import Section from '../components/section'

const HeadshotsPage = ({data, location}: PageProps<Queries.HeadshotsPageQuery>) => {
	const headshots = data?.headshots?.items?.filter(Boolean) as Contentful.Headshot[] ?? []
	
	return <StandardLayout location={location}>
		<Helmet>
			<body className="headshots-page" />
		</Helmet>

		<h1 className="card-style-block-text">Headshots</h1>
		<hr />
		<Section title="Winter 2023">
			<Gallery
				className="headshot-gallery"
				images={headshots.map((headshot) => headshot.image)}
			/>
		</Section>
	</StandardLayout>
}

export default HeadshotsPage

export const query = graphql`
	query HeadshotsPage {
		headshots: contentfulOrdering(
			name: {eq: "Headshot Page Headshots"}
		) {
			items {
				... on ContentfulHeadshot {
					name
					image {
						title
						url
						image: gatsbyImage(width: 350)
					}
				}
			}
		}
	}
`
